export function addCurrentPageEventListener(type, listener, el = document) {
  el.addEventListener(type, listener);

  el.addEventListener(
    "turbo:before-render",
    () => {
      el.removeEventListener(type, listener);
    },
    { once: true },
  );
}

export function getQueryStringParams() {
  return new URLSearchParams(window.location.search);
}

export function getElementByName(name) {
  return document.getElementsByName(name)[0];
}

export const comparisonOptionsMap = new Map([
  ["previous_mark", "Previous"],
  ["benchmark_50", "Industry P50"],
  ["benchmark_75", "Industry P75"],
  ["benchmark_90", "Industry P90"],
  ["vs_prev", "Previous"],
  ["vs_org", "Org average"],
  ["vs_50th", "Industry P50"],
  ["vs_75th", "Industry P75"],
  ["vs_90th", "Industry P90"],
  ["benchmark_50", "Industry P50"],
  ["benchmark_75", "Industry P75"],
  ["benchmark_90", "Industry P90"],
]);

export function setQueryStringParam(name, value) {
  const params = new URLSearchParams(window.location.search);

  value === null ? params.delete(name) : params.set(name, value);

  window.history.replaceState(
    window.history.state,
    "",
    decodeURIComponent(`${window.location.pathname}?${params}`),
  );
}

export function snapshotSquadsFromCache(snapshotId) {
  const { squadsDigests, currentUser } = window;
  const digest = squadsDigests[snapshotId];
  const cacheKey = `squads_${currentUser.account_id}_${snapshotId}_v2`;

  return getWithDigest(cacheKey, digest) || [];
}

export function deleteQueryStringParam(key) {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.pushState({}, "", url);
}

export function clearQueryParams() {
  const url = new URL(window.location);
  url.search = "";
  window.history.pushState({}, "", url);
}

export function generateOrgComparisonValues(resource, orgValue) {
  const values = resource.values.map((item) => {
    return {
      date: item.date,
      label: "Vs org",
      value: orgValue,
    };
  });

  return values;
}

export function generatePreviousComparisonValues(resource) {
  let currentLabel = null;
  let previousLabel = null;

  const values = resource.values.map((item, index) => {
    previousLabel = currentLabel;
    currentLabel = item.label;

    if (index === 0) return null;

    return {
      date: item.date,
      label: previousLabel,
      value: resource.values[index - 1].value,
    };
  });

  values.shift();

  return values;
}

export function generateBenchmarkComparisonValues(
  resource,
  subResourceType,
  benchmark,
) {
  return resource.values.map((item) => {
    return {
      date: item.date,
      label: comparisonOptionsMap.get(benchmark),
      value: resource[subResourceType][benchmark],
    };
  });
}

export function postData(url, params = {}) {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.ok) return response.json();

      alert("Something went wrong. Please reload the page and try again.");
    })
    .catch((e) => {
      console.error(`e:`, e);
      alert("Your connection dropped. Please reload the page and try again.");
    });
}

export function putData(url, params = {}) {
  return fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.ok) return response.json();

      alert("Something went wrong. Please reload the page and try again.");
    })
    .catch((e) => {
      console.error(`e:`, e);
      alert("Your connection dropped. Please reload the page and try again.");
    });
}

export function isBlankStr(str) {
  if (str === null || str.length < 1) return true;

  return false;
}

export function toSentence(arr) {
  if (arr.length < 2) {
    return arr.join(", ");
  }
  if (arr.length == 2) {
    return `${arr[0]} and ${arr[1]}`;
  }

  let sentence = "";
  sentence = arr.slice(0, arr.length - 1).join(", ");

  return sentence + `, and ${arr[arr.length - 1]}`;
}

export function upcaseFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function outOfView(container, el) {
  var cTop = container.scrollTop;
  var cBottom = cTop + container.clientHeight;
  var eTop = el.offsetTop - container.offsetTop;
  var eBottom = eTop + el.clientHeight;

  return eBottom > cBottom;
}

export function eqArrays(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const unfoundItems = arr1.filter((x) => {
    return !(
      arr2.includes(x) || JSON.stringify(arr2).includes(JSON.stringify(x))
    );
  });

  return unfoundItems.length < 1;
}

export function eqSets(set1, set2) {
  if (set1.size !== set2.size) return false;

  for (const i of set1) if (!set2.has(i)) return false;

  return true;
}

export function arrayRange(start, stop, step) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_value, index) => start + index * step,
  );
}

export const prevKeys = ["ArrowUp", "ArrowLeft"];
export const nextKeys = ["ArrowDown", "ArrowRight"];
export const navKeys = ["ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight"];

export function prefetchImages(arr) {
  var cache = document.createElement("CACHE");
  cache.style = "position:absolute;z-index:-1000;opacity:0;";
  document.body.appendChild(cache);

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== null) {
      let img = new Image();
      img.src = arr[i];
      img.style = "position:absolute";
      cache.appendChild(img);
    }
  }
}

export function setWithDigest(key, value, digest) {
  const item = { value, digest };

  setLocalStorageItem(key, JSON.stringify(item));
}

export function getWithDigest(key, digest) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;

  const item = JSON.parse(itemStr);

  if (digest !== item.digest) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  setLocalStorageItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;

  const item = JSON.parse(itemStr);

  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function indexBy(array, attr) {
  return array.reduce((accumulator, currentValue) => {
    const key = currentValue[attr];
    accumulator[key] = currentValue;
    return accumulator;
  }, {});
}

export function setLocalStorageItem(name, value) {
  try {
    localStorage.setItem(name, value);
  } catch (e) {
    setTimeout(() => localStorage.clear(), 0);
  }
}

export function getLocalStorageItem(name, defaultVal) {
  try {
    return localStorage.getItem(name) || defaultVal;
  } catch (e) {
    return defaultVal;
  }
}

export function objectToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== null &&
      obj[key] !== undefined
    ) {
      if (obj[key] == false || !!obj[key]) {
        const value = obj[key];

        if (Array.isArray(value)) {
          value.forEach((item) => {
            keyValuePairs.push(
              encodeURIComponent(key) + "[]=" + encodeURIComponent(item),
            );
          });
        } else {
          keyValuePairs.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(value),
          );
        }
      }
    }
  }
  return keyValuePairs.join("&");
}

export async function fetchWithRetry(url, options = {}, retries = 1) {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Fetch error: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries <= 0) {
      throw error;
    }
    console.warn(`Fetch error: ${error}. Retrying...`);
    return await fetchWithRetry(url, options, retries - 1);
  }
}

// Relies on the status: 'wait' response in the JSON body and retries once every second
// until the data is available.
export async function fetchWithWait(url, options = {}, retries = 26) {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Fetch error: ${response.status}`);
  }

  const responseBody = await response.json();

  if (responseBody.status === "wait" && retries > 0) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return fetchWithWait(url, options, retries - 1);
  }

  return responseBody; // need to return the json since we have to inspect it to determine if we need to wait
}

function sortByScore(args) {
  return (a, b) => {
    if (a.score == null && b.score == null) return 0;

    if (a.score == null) return 1;
    if (b.score == null) return -1;

    if (args?.reverse) return b.score - a.score;
    else return a.score - b.score;
  };
}

function sortByName(a, b) {
  return a.name.localeCompare(b.name, undefined, { sensitivity: "base" });
}

function sortByGroupName(a, b) {
  if (!a.group) return;

  return a.group.localeCompare(b.group, undefined, {
    sensitivity: "base",
  });
}

function sortBySampleSize(a, b) {
  return a.sample_size - b.sample_size;
}

function sortByPriorityRank(args) {
  return (a, b) => {
    if (a.priority_rank == null && b.priority_rank == null) {
      return a.score - b.score;
    }
    if (a.priority_rank == null) return 1;
    if (b.priority_rank == null) return -1;

    if (a.priority_rank === b.priority_rank) {
      if (args?.reverse) return b.score - a.score;
      return a.score - b.score;
    }

    if (args?.reverse) {
      return b.priority_rank - a.priority_rank;
    }

    return a.priority_rank - b.priority_rank;
  };
}

function sortBySnapshotSquadName(a, b) {
  return a["snapshot_squad_name"]
    .toLowerCase()
    .localeCompare(b["snapshot_squad_name"].toLowerCase());
}

function calculateDxiComparison(
  item,
  compareTo,
  dxi50,
  dxi75,
  dxi90,
  orgDxiScore,
) {
  if (compareTo == "vs_50th") {
    return item.score - dxi50;
  } else if (compareTo == "vs_75th") {
    return item.score - dxi75;
  } else if (compareTo == "vs_90th") {
    return item.score - dxi90;
  } else if (compareTo == "vs_prev") {
    return item.previous_score != null
      ? item.score - item.previous_score
      : null;
  } else if (compareTo == "vs_org") {
    return item.score - orgDxiScore;
  }
}

function sortByDxiComparison(compareTo, args) {
  const { dxi50, dxi75, dxi90, orgDxiScore } = args;

  return (a, b) => {
    // put nulls first ascending so high scores do not include them
    if (a.score == null && b.score == null) return 0;
    if (a.score == null) return 1;
    if (b.score == null) return -1;

    if (args?.reverse) {
      return (
        calculateDxiComparison(b, compareTo, dxi50, dxi75, dxi90, orgDxiScore) -
        calculateDxiComparison(a, compareTo, dxi50, dxi75, dxi90, orgDxiScore)
      );
    } else {
      return (
        calculateDxiComparison(a, compareTo, dxi50, dxi75, dxi90, orgDxiScore) -
        calculateDxiComparison(b, compareTo, dxi50, dxi75, dxi90, orgDxiScore)
      );
    }
  };
}

function sortByComparison(compareTo) {
  return (a, b) => {
    if (a[compareTo] == b[compareTo]) return b.priority - a.priority;
    return a[compareTo] - b[compareTo];
  };
}

function sortByCalculatedComparison(compareTo, args) {
  const { orgScore, reverse } = args;

  return (a, b) => {
    if (a.score == null) return 1;
    if (b.score == null) return -1;

    let compareToAttribute, aValue, bValue;

    if (compareTo == "vs_50th") {
      compareToAttribute = "benchmark_50";
    } else if (compareTo == "vs_75th") {
      compareToAttribute = "benchmark_75";
    } else if (compareTo == "vs_90th") {
      compareToAttribute = "benchmark_90";
    } else if (compareTo == "vs_prev" || compareTo == "vs_previous") {
      compareToAttribute = "previous_score";
    } else if (compareTo == "vs_org") {
      if (a["org_score"]) {
        compareToAttribute = "org_score";
      } else {
        // For team breakdowns we don't calculate the org score again but pull it from
        // the selected item's score
        aValue = a.score - orgScore;
        bValue = b.score - orgScore;
      }
    }

    if (compareToAttribute) {
      if (a[compareToAttribute] == null && b[compareToAttribute] == null)
        return 0;
      if (a[compareToAttribute] == null) return 1;
      if (b[compareToAttribute] == null) return -1;

      aValue = a.score - a[compareToAttribute];
      bValue = b.score - b[compareToAttribute];
    }

    if (aValue == bValue && (!!a.priority || !!b.priority))
      return b.priority - a.priority;

    if (reverse) return bValue - aValue;

    return aValue - bValue;
  };
}

function sortByTriageSelection(a, b) {
  return a.triage_selection?.localeCompare(b.triage_selection, undefined, {
    sensitivity: "base",
  });
}

function sortByCommentCount(a, b) {
  if (a.comment_count === b.comment_count) return a.score - b.score;

  return b.comment_count - a.comment_count;
}

function sortByCount(a, b) {
  if (a.count === b.count) return a.score - b.score;

  return a.count - b.count;
}

function sortByPriortyScoreAndcount(a, b) {
  if (a.priority_rank == null && b.priority_rank == null) {
    // If both priority ranks are null, sort by score first
    if (a.score === b.score) {
      // If scores are also the same, sort by count
      return a.count - b.count;
    }
    return a.score - b.score;
  }

  if (a.priority_rank == null) return 1;
  if (b.priority_rank == null) return -1;

  if (a.priority_rank === b.priority_rank) {
    // If priority ranks are the same, sort by score
    if (a.score === b.score) {
      // If scores are also the same, sort by count
      return a.count - b.count;
    }
    return a.score - b.score;
  }

  return a.priority_rank - b.priority_rank;
}

function sortByCompletionStatus(a, b) {
  if (!!a.snapshot_response_id && !b.snapshot_response_id) return -1;
  else if (!a.snapshot_response_id && !!b.snapshot_response_id) return 1;
  else return 0;
}

export function sortFunc(sortBy, compareTo, args = {}) {
  if (sortBy == "name") return sortByName;
  if (sortBy == "group") return sortByGroupName;
  if (sortBy == "snapshotSquadName") return sortBySnapshotSquadName;
  if (sortBy == "score") return sortByScore(args);
  if (sortBy == "priority") return sortByPriorityRank(args);
  if (sortBy == "sampleSize") return sortBySampleSize;
  if (sortBy == "commentCount") return sortByCommentCount;
  if (sortBy == "triageSelection") return sortByTriageSelection;
  if (sortBy == "completionStatus") return sortByCompletionStatus;
  if (sortBy == "count") return sortByCount;
  if (sortBy == "calculatedComparison")
    return sortByCalculatedComparison(compareTo, args);
  if (sortBy == "comparison") return sortByComparison(compareTo);
  if (sortBy == "dxiComparison") return sortByDxiComparison(compareTo, args);
  if (sortBy == "priorityScoreAndCount") return sortByPriortyScoreAndcount;
}

export function numberToSpelling(number) {
  const spelling = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
  };
  return spelling[number];
}

export function scrollToHash() {
  const hash = window.location.hash;
  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      targetElement.scrollIntoView();
    }
  }
}

export function isEmail(email) {
  // Regular expression for validating an email address
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function differenceInSets(set1, set2) {
  return new Set([...set1].filter((item) => !set2.has(item)));
}
